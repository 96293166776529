@charset "UTF-8";

/*!
 * Bootstrap  v5.3.2 (https://getbootstrap.com/)
 * Copyright 2011-2023 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */


:root {
  --light: #eee;
  --dark: #342F4E;
  --darker: #1c1c1c;
  --blue: #61DAFB;
  --crimson: #DB3052;
  --accent: #FF2C55;
  --accent-dark: #ba2140;
  --primary: #F7CD0A;
}

.App {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

html {
  font-size: 16px;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.wrapper {
  display: block;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 100px;
}

a {
  color: inherit;
  text-decoration: inherit;
}

.flex {
  display: flex;
}

ul {
  list-style: none;
  padding: 0
}

.rounded {
  border-radius: 0.25rem;
}

.pt-9 {
  padding-top: 5rem !important;
}

/* .row {
  max-width: 1200px !important;
} */

/* logo */
.header-logo {
  z-index: 3;
  line-height: 1;
}

.header-logo a {
  display: block;
  margin: 0;
  padding-left: 20px;
  outline: 0;
  border: none;
}

.header-logo img {
  width: 60px;
  margin: 0;
  vertical-align: bottom;
}

.lg-5 {
  flex: none;
  width: 41.66667%;
}

.lg-6 {
  flex: none;
  width: 50%;
}

.lg-12 {
  flex: none;
  width: 100%;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Navigation Start */

.navbar {
  background-color: var(--darker);
}

.nav-link {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 60px;
  padding: 0 1.2rem;
}

.nav-link:hover {
  color: var(--accent);
}

.navbar-nav li {
  padding: 0;
  margin: 0;
}

/* Navigation End*/

/* Login / Sign up Pages Start*/

.show-password {
  color: var(--accent);
  cursor: pointer;
}

.show-password:hover {
  color: var(--accent-dark);
}

.join-link {
  color: var(--accent);
  cursor: pointer;
  text-decoration: underline;
}

.join-link:hover {
  color: var(--accent-dark);
}

/* Banner Start */

.body-text-2 {
  font-size: 18px;
  line-height: 30px;
}

.banner {
  z-index: 3;
  background-color: black;
  width: 100%;
  height: 100vh;
  min-height: 816px;
  overflow: hidden;
  position: relative;
}

.banner_bg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: url(../src/assets/images/hero-bg-3000.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.banner_bg::before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: black;
  pointer-events: none;
  opacity: 0.2;
}

.banner_bg::after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, black 15%, rgba(0, 0, 0, 0) 100%);
  pointer-events: none;
  opacity: 0.3;
}

.banner_content {
  z-index: 2;
  align-items: flex-end;
  justify-content: space-between;
  max-width: 1400px;
  height: 100%;
  font-weight: 300;
  color: white;
  padding-top: 20vh;
  padding-bottom: 9.2rem;
  position: relative;
}

.custom_row {
  width: 92%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
}

.banner_content_inner {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.banner_content_left,
.banner_content_right {
  flex: none;
}

.banner_content_left {
  width: calc(100% - 360px - 22px);
  padding-right: 44px;
}

.banner_content_right {
  width: 360px;
  padding-top: 32px;
}

.banner_content_title {
  font-weight: 500;
  font-size: 88px;
  line-height: 1.227;
  color: white;
  margin-top: 0;
}

.banner_content_buttons {
  display: flex;
}

/* Banner End*/

/* About Start */

.about {
  padding-top: 160px;
  padding-bottom: 160px;
  background-color: #e9ebeb;
}

.about_info {
  margin-top: 128px;
}

.about_info_slider {
  padding-bottom: 64px;
}

.about_info_slider h4 {
  margin-top: 0;
  margin-bottom: 24px;
}

.section-header {
  justify-content: space-between;
  position: relative;
  margin-bottom: 32px;
}

.section-header .title,
.section-header .desc {
  z-index: 1;
  position: relative;
}

.section-header .title {
  padding-left: 28px;
}

.section-header .desc {
  font-weight: 300;
  color: #999b9b;
  padding-left: 22px;
  padding-right: 22px;
}

.section-header::before {
  content: attr(data-num);
  font-weight: 600;
  font-size: 8em;
  line-height: 1;
  color: white;
  position: absolute;
  top: -0.35em;
  left: 0;
}

.lg-6 {
  flex: none;
  width: 50%;
}

.about_screens {
  margin-top: 128px;
}

.screens {
  height: 736px;
}

.screen {
  width: 340px;
}

.screen img {
  max-width: 100%;
  box-shadow: 0 40px 24px -40px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
}

.about_how2 {
  margin-top: 192px;
}

.about_how2_screens video {
  border-radius: 16px;
}

.about_how2 .about_how2_content {
  margin-top: 64px;
}

.about_how2 .about_how2_process_block,
.about_how2 .about_how2_screens_block {
  flex: none;
}

.about_how2 .about_how2_process_block {
  width: calc(100% - 432px);
}

.about_how2_process {
  counter-reset: ctr;
  max-width: 560px;
}

.about_how2_process_step {
  margin-bottom: 0;
}

.about_how2_process_step h3 {
  line-height: 48px;
  margin-bottom: 4px;
  position: relative;
}

.about_how2_process_step h3::before {
  content: counter(ctr);
  counter-increment: ctr;
  display: block;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: #fff;
  background-color: var(--accent);
  height: 48px;
  width: 48px;
  line-height: 48px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
}

.about_how2 p {
  margin-bottom: 32px !important;
  line-height: 32px;
  font-size: 18px;
}

.about_how2_process_step h3,
.about_how2_process_step p {
  padding-left: 72px;
}

.about_how2_screens {
  height: 736px;
  margin-top: 30px;
  position: relative;
}

.about_why {
  margin-top: 192px;
}

.about_why_icon_block {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  width: 64px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.05);
}

/* About End */

/* Downloads start */

.download {
  padding-top: 192px;
  padding-bottom: 128px;
  background-color: var(--accent);
  ;
}

.download_header {
  max-width: 800px;
  text-align: center;
}

.download_header h2 {
  color: white;
}

.download_header .lead {
  color: rgba(255, 255, 255, 0.7);
}

.download_badges_block {
  margin-top: 16px;
}

.download_badges {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
}

.download_badges a {
  display: block;
  width: 232px;
  margin: 0 0.8rem 16px;
}

.download_badges img {
  display: block;
  width: 232px;
  margin: 0 0.8rem 16px;
}

.download_badges img {
  margin: 0;
  vertical-align: bottom;
}

/* Downloads ends */

/* Footer Start */

.footer {
  background-color: var(--darker);
  padding-top: 84px;
  padding-bottom: 112px;
  color: rgba(255, 255, 255, 0.4);
  position: relative;
}

.footer h2 {
  color: var(--accent);
  margin-top: 0;
  margin-bottom: 0;
}

.btn.submit,
input[type="button"].submit {
  background: var(--accent);
  border-color: var(--accent);
  color: #fff;
}

.btn.submit:focus,
.btn.submit:hover,
input[type="submit"].submit:hover {
  background: rgb(178, 84, 84);
  border-color: rgb(178, 84, 84);
  color: #fff;
}

.footer_subscribe {
  margin-top: 32px;
}

.footer_subscribe form {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 0;
}

.footer_subscribe input[type="email"],
.footer_subscribe input[type="submit"],
.footer_subscribe .ms-status {
  flex: none;
}

.footer_subscribe input[type="email"] {
  --input-height: 7.2rem;
  width: calc(100% - 200px);
  background-color: rgba(255, 255, 255, 0.03);
  color: white;
  border: none;
  border-radius: 8px 0 0 8px;
  padding: 19px;
  text-align: left !important;
  margin: 0;
}

.footer_subscribe input[type="email"]:focus,
.footer_subscribe input[type="email"]:hover {
  box-shadow: none;
}

.footer_subscribe input[type="submit"] {
  --btn-height: 230px;
  width: 200px;
  border-radius: 0 8px 8px 0;
  margin: 0;
}

.footer_subscribe .mc-status {
  width: 100%;
  margin-top: 12px;
  margin-bottom: 32px;
  font-weight: 500;
  color: var(--accent);
  text-align: center;
}

.footer_contact .footer_email {
  font-weight: 500;
  font-size: 26px;
  line-height: 32px;
  color:var(--accent);
}

.footer_contact.footer_email:focus,
.footer_contact .footer_email:hover {
  color: rgb(178, 84, 84);
}

.footer_social {
  list-style: none;
  display: flex;
  margin-left: 0;
}

.footer_social li {
  padding-left: 0;
  margin-right: 0.8rem;
}

.footer_social a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 50%;
}

.footer_social a:focus,
.footer_social a:hover {
  background-color: #0c0c0c;
}

.footer_socialsvg {
  height: 20px;
  width: 20px;
}

.footer_social svg path {
  fill: white;
}


/* Footer End */

/* Minimalist Footer for recipe pages Start */

.minFooter {
  position:fixed;
  left:0;
  bottom:0;
  right:0;
  width: 100%;
  height: 60px;
  line-height: 60px;
  background-color: #f5f5f5;
}

.minFooter p {
  margin-bottom: 0;
}

.minFooter a {
  color: #007bff;
  text-decoration: none;
}

.minFooter a:hover {
  text-decoration: underline;
}

/* Minimalist Footer for recipe pages END*/


.border-gray {
  border-color: #d1d5db;
}

ol {
  list-style: decimal;
}

.phase {
  /* display: flex; */
  justify-content: space-between;
  list-style: decimal;
  padding-top: 10px;
  padding-bottom: 10px;
}

.phase .duration,
.text-end {
  /* display: flex; */
  color: var(--accent);
}

.container.sidebar.desk-only .top .filter-btn {
  padding: 0.5em 1.7em 0.5em 1.7em;
  margin-bottom: 0.6em;
  text-indent: 1em;
  font-weight: bold;
  list-style: none;
  background-repeat: no-repeat;
  background-position: center left;
  overflow: hidden;
}

.text-display-1 {
  font-size: 50px;
  font-weight: 500;
  line-height: 1.191;
  margin-top: 0;
}

.search-box {
  width: auto !important;
  margin: 4px;
}

.search-box input {
  margin-bottom: 0;
}

li[data-type="all"] {
  background-position: 0.65em !important;
  background: url(./assets/images/cup-hot-fill.svg) no-repeat;

}

li[data-type="aeropress"] {
  background-image: url('./assets/images/ic_mini_day_24px_aeropress.svg')
}

li[data-type="chemex"] {
  background-image: url('./assets/images/ic_mini_day_24px_chemex.svg')
}

li[data-type="french_press"] {
  background-image: url('./assets/images/ic_mini_day_24px_french_press.svg')
}

li[data-type="moka_pot"] {
  background-image: url('./assets/images/ic_mini_day_24px_moka.svg')
}

li[data-type="drip"] {
  background-image: url('./assets/images/ic_mini_day_24px_drip.svg')
}

li[data-type="default"] {
  background-image: url('./assets/images/kaffeine_logo.svg')
}

/* Sidebar Filter Buttons for the coffee page */
li[data-type="usa"],
li[data-type="europe"],
li[data-type="uk"],
li[data-type="rest_of_world"],
li[data-type="craft"],
li[data-type="supermarket"],
li[data-type="chain"] {
  padding-left: 0.5em !important;
  text-indent: 0px !important;
}

li[data-type="usa"]::before {
  content: "\1F1FA\1F1F8";
  /* Unicode for the USA flag emoji */
  margin-right: 1.15em;
  /* Adjust spacing between the emoji and the text */
}

li[data-type="europe"]::before {
  content: "\1F1EA\1F1FA";
  /* Unicode for the Europe flag emoji */
  margin-right: 1.15em;
  /* Adjust spacing between the emoji and the text */
}

li[data-type="uk"]::before {
  content: "\1F1EC\1F1E7";
  /* Unicode for the UK flag emoji */
  margin-right: 1.15em;
  /* Adjust spacing between the emoji and the text */
}

li[data-type="rest_of_world"]::before {
  content: "\1F30D";
  /* Unicode for the globe emoji */
  margin-right: 1.15em;
  /* Adjust spacing between the emoji and the text */
}

li[data-type="craft"]::before {
  content: "\1F680";
  /* Unicode for the globe emoji */
  margin-right: 1.15em;
  /* Adjust spacing between the emoji and the text */
}

li[data-type="supermarket"]::before {
  content: "\1F6D2";
  /* Unicode for the globe emoji */
  margin-right: 1.15em;
  /* Adjust spacing between the emoji and the text */
}

li[data-type="chain"]::before {
  content: "\1F3ED";
  /* Unicode for the globe emoji */
  margin-right: 1.15em;
  /* Adjust spacing between the emoji and the text */
}

.sidebar {
  width: full;
  max-width: 15rem;
  margin-left: 0;
  margin-left: 0;
  position: sticky;
}

.nav-link {
  font-size: 16px;
}

.sticky-offset {
  top: 76px;
}

.sort-bar {
  width: 100%;
  overflow: hidden;
  display: inline-block;
  background-color: #fff;
}

.summary {
  float: left;
  padding: 17px;
}

.sort-by {
  max-width: 150px;
  float: right;
  cursor: pointer;
  text-align-last: center;
  text-align: center;
  font-weight: 800;
  font-size: 1em;
  /* position: relative; */
  display: flex;
  margin: 7px;
}

.sort-by select {
  padding-left: 14px;
  padding-right: 14px;
  padding: 10px;
  top: 0;
  right: 0;
  /* font-weight: 800; */
  height: 100%;
  border: 1px solid #dddddd;
  border-radius: 12px;
  background: #fff;
  background: #fff;
  color: #1c1c1c;
}

.sort-by select {
  max-width: 100%;
}

.popup-btn {
  --bs-btn-padding-x: 1rem;
  --bs-btn-color: #000;
  --bs-btn-bg: var(--primary);
  --bs-btn-border-color: var(--primary);
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #fbe6a4;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: var(--primary);
  --bs-btn-disabled-border-color: var(--primary);
  max-height: 50px;
  width: auto !important;
}

.play-btn {
  --bs-btn-hover-color: #ff91a7;
  --bs-btn-hover-bg: #ff91a7;
  --bs-btn-hover-border-color: var(--accent);
  margin-left: 20px;
  padding-left: 7px;
  padding-right: 7px;
  background-color: var(--accent);
}

.filter-btn:hover {
  cursor: pointer;
  background-color: #e5ecd1;
}

.filter-btn.active {
  background-color: #e5ecd1;
}

.vote-btn:hover {
  cursor: pointer;
  color: #cc1111;
  opacity: 0.5;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.25rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.aeropress {
  background-repeat: no-repeat;
  background-position: bottom 0.7em right 0.7em;
  background-image: url('./assets/images/ic_mini_day_24px_aeropress.svg')
}

.moka_pot {
  background-repeat: no-repeat;
  background-position: bottom 0.7em right 0.7em;
  background-image: url('./assets/images/ic_mini_day_24px_moka.svg')
}

.french_press {
  background-repeat: no-repeat;
  background-position: bottom 0.7em right 0.7em;
  background-image: url('./assets/images/ic_mini_day_24px_french_press.svg')
}

.drip {
  background-repeat: no-repeat;
  background-position: bottom 0.7em right 0.7em;
  background-image: url('./assets/images/ic_mini_day_24px_drip.svg')
}

.chemex {
  background-repeat: no-repeat;
  background-position: bottom 0.7em right 0.7em;
  background-image: url('./assets/images/ic_mini_day_24px_chemex.svg')
}

.name {
  /* text-indent: 2em !important; */
  padding-left: 2em;
  background-repeat: no-repeat;
  background-position: center left;
  overflow: auto;
}

.recipe-title .name[data-type="aeropress"] {
  background-image: url('./assets/images/ic_mini_day_24px_aeropress.svg')
}

.recipe-title .name[data-type="chemex"] {
  background-image: url('./assets/images/ic_mini_day_24px_chemex.svg')
}

.recipe-title .name[data-type="french_press"] {
  background-image: url('./assets/images/ic_mini_day_24px_french_press.svg')
}

.recipe-title .name[data-type="moka_pot"] {
  background-image: url('./assets/images/ic_mini_day_24px_moka.svg')
}

.recipe-title .name[data-type="drip"] {
  background-image: url('./assets/images/ic_mini_day_24px_drip.svg')
}

.mobile_filter {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  z-index: 30;
  position: fixed;
  margin: 0;
}

.mobile-sort-select {
    background-color: #1c1c1c; /* Background color */
    color: white; /* Text color */
    border: none;
    outline: none;
}

.mobile-filters-select {
  background-color: var(--darker); /* Background color */
  color: white; /* Text color */
  border: none;
  outline: none;
}

.desk-only {
  display: block;
}

.mobile-only {
  display: none;
}

.button {
  background-color: var(--darker);
  height: 80px;
}

.roundup-list {
  justify-content: center;
  min-width: 400px;
  width: 40%;
}

.roundup-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #eee;
  background-repeat: no-repeat;
  text-indent: 3em;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 0px;
  overflow: hidden;
}

.brew-roundup-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 82%;
  border-bottom: 1px solid #eee;
  background-repeat: no-repeat;
  text-indent: 3em;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 0px;
  overflow: hidden;
}

/* Brew Details and Popup Page Icons */

.brew-roundup-row[data-type="coffee_weight"],
.popup-inner .coffee-label {
  background-position: 0em;
  background-image: url('./assets/images/ic_mini_day_24px_coffee.svg')
}

.brew-roundup-row[data-type="coffee_grind"],
.popup-inner .grind-label {
  background-position: 0em;
  background-image: url('./assets/images/ic_mini_day_24px_grind_med.svg')
}

.brew-roundup-row[data-type="water"],
.popup-inner .water-label {
  background-position: 0em;
  background-image: url('./assets/images/ic_mini_day_24px_water.svg')
}

.brew-roundup-row[data-type="water_temp"],
.popup-inner .temp-label {
  background-position: 0em;
  background-image: url('./assets/images/ic_mini_day_24px_temp.svg')
}

.brew-roundup-row[data-type="duration"],
.popup-inner .time-label {
  background-position: 0em;
  background-image: url('./assets/images/ic_mini_day_24px_time.svg')
}

.brew-roundup-row[data-type="ratio"],
.popup-inner .ratio-label {
  background-position: 0em;
  background-image: url('./assets/images/ic_mini_day_24px_ratio.svg')
}

.brew-roundup-row[data-type="beans"],
.popup-inner .beans-label {
  background-position: 0em;
  background-image: url('./assets/images/ic_mini_day_24px_beans.svg')
}

/* Coffee Details page and pop icons */

.roundup-row[data-type="coffee_brand"],
.popup-inner .coffee_brand {
  background-position: 0em;
  background-image: url('./assets/images/material_icons/factory_black_24dp.svg')
}

.roundup-row[data-type="coffee_product"],
.popup-inner .product-label {
  background-position: 0em;
  background-image: url('./assets/images/material_icons/spa_black_24dp.svg')
}

.popup-inner .desc-label {
  background-position: 0em;
  background-image: url('./assets/images/material_icons/description_black_24dp.svg')
}

.roundup-row[data-type="coffee_pkg_weight"],
.popup-inner .weight-label {
  background-position: 0em;
  background-image: url('./assets/images/material_icons/weight-hanging-solid.svg')
}

.roundup-row[data-type="roast_profile"],
.popup-inner .roast-profile-label {
  background-position: 0em;
  background-image: url('./assets/images/material_icons/gauge_black_24dp.svg')
}

.roundup-row[data-type="hyperlink"],
.popup-inner .link-label {
  background-position: 0em;
  background-image: url('./assets/images/material_icons/link_black_24dp.svg')
}

.roundup-row[data-type="origin"],
.popup-inner .origin-label {
  background-position: 0em;
  background-image: url('./assets/images/material_icons/public_black_24dp.svg')
}

.roundup-row[data-type="process"],
.popup-inner .process-label {
  background-position: 0em;
  background-image: url('./assets/images/material_icons/water_black_24dp.svg')
}

.roundup-row[data-type="notes"],
.popup-inner .notes-label {
  background-position: 0em;
  background-image: url('./assets/images/material_icons/aroma_black_24dp.svg')
}

.roundup-row[data-type="producer"],
.popup-inner .producer-label {
  background-position: 0em;
  background-image: url('./assets/images/material_icons/people_black_24dp.svg')
}

.roundup-row[data-type="grind"],
.popup-inner .sug-grind-label {
  background-position: 0em;
  background-image: url('./assets/images/material_icons/speed_black_24dp.svg')
}

.roundup-row[data-type="varietal"],
.popup-inner .varietal-label {
  background-position: 0em;
  background-image: url('./assets/images/material_icons/compost_black_24dp.svg')
}

.roundup-row[data-type="altitude"],
.popup-inner .altitude-label {
  background-position: 0em;
  background-image: url('./assets/images/material_icons/cloud_black_24dp.svg')
}

.popup-inner .country-label {
  background-position: 0em;
  background-image: url('./assets/images/material_icons/flag_circle_black_24dp.svg')
}

.popup-inner .name-label {
  background-position: 0em;
  background-image: url('./assets/images/material_icons/storefront_black_24dp.svg')
}

.roundup-row[data-type="roast_date"],
.popup-inner .roast-date-label {
  background-position: 0em;
  background-image: url('./assets/images/material_icons/calendar_month_black_24dp.svg')
}

/* Popup Ingredients Form  */
.popup-inner .col-form-label {
  background-repeat: no-repeat;
  text-indent: 2.5em;
}

.ingd_label {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.ingd_value {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.coffee_label {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.coffee_value {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.phase_label {
  background-position: top 0.2em left 0em;
  margin: 0px;
  background-image: url('./assets/images/ic_mini_day_24px_phase.svg')
}

#picker_aeropress {
  background-repeat: no-repeat;
  text-indent: 1.5em;
  background-position: top 0em left 0em;
  background-image: url('./assets/images/ic_mini_day_24px_aeropress.svg')
}

.recipe-grid .col {
  max-height: 150px;
  overflow: hidden;
}

.card-description {
  max-height: 50px;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.desc-inner {
  /* border: 1px solid blue; */
  overflow: hidden;
  text-overflow: ellipsis;
}

.recipe-title {
  background-color: #fff;
}

.sticky-top {
  z-index: 5;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
  text-align: left;
}

.popup-inner {
  background-color: #fff;
  margin-top: 65px;
  padding: 2.5rem;
  border-radius: 0.5rem;
  width: 100%;
  max-width: 850px;
  overflow: auto;
  max-height: calc(100vh - 6rem);
}

.popup h3 {
  margin-bottom: 1rem;
  color: var(--darker);
}

.form-group {
  margin-bottom: 1rem;
}

.form-group:last-of-type {
  margin-bottom: 0;
}

input,
textarea {
  display: block;
  width: 100%;
  padding: 0.5rem;
  resize: vertical;
  max-height: 200px;
  margin-bottom: 0.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--darker);
}

.form-group button {
  margin-top: 1rem;
}

/* Profile Page Start */

.gradient-custom {
  /* fallback for old browsers */
  background: #f6d365;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(to right bottom, rgba(246, 211, 101, 1), rgba(255, 45, 85, 1));

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to right bottom, rgba(246, 211, 101, 1), rgb(255, 45, 85))
}

.profile-btn {
  fill: white;
  width: 40px;
  cursor: pointer;
  transition: fill 0.3s;
}

.profile-btn:hover {
  fill: #ebebeb;
}

.modal {
  margin-top: 6rem;
}

/* Profile Page End */

@media screen and (max-width: 1600px) {
  .banner_content {
    max-width: 1160px;
  }

  .banner_content_title {
    font-size: calc(88px * 0.95);
  }
}

@media screen and (max-width: 1400px) {
  .banner_content {
    max-width: 1040px;
  }

  .banner_content_right {
    padding-top: 20px;
  }

  .banner_content_title {
    font-size: calc(88px * 0.82);
  }

  .banner_content_title br {
    display: none;
  }
}

@media screen and (max-width: 1204px) {
  .desk-only {
    display: none;
  }

  .roundup {
    width: auto;
    padding: 0px !important;
    margin: 0px !important;
  }

  .popup-inner {
    max-width: 100%;
    max-height: calc(100vh - 3rem);
    padding-left: 10px !important;
    padding-right: 10px !important;
    margin: 100px 0px 0px 0px !important;
  }

  .coffee_label {
    width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .coffee_value {
    width: 50%;
  }

  .ingd_label {
    width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .ingd_value {
    width: 50%;
  }

  .phase_dur_label {
    text-indent: 0px !important;
  }

  .phase_label {
    background-position: top 0.6em left 0.8em;
    width: 30%;
    padding-right: 0px;
  }

  .phase_name {
    padding-left: 0px;
    width: 50%;
  }

  .phase_dur {
    width: 15%;
  }

  .phase_row {
    display: flex;
  }

  .description {
    /* white-space: pre-line; */
    width: auto;
    padding: 0px !important;
    margin: 0px 0px 35px 0px !important;
  }

  .recipe-title {
    width: auto;
    margin: 0px !important;
  }

  .roundup-row {
    width: auto !important;
    flex-wrap: nowrap;
  }

  .roundup-value {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .mobile-only {
    display: flex;
  }

  .banner_content {
    max-width: 900px;
  }

  .banner_content_title {
    font-size: calc(88px * 0.76);
  }

}

@media screen and (max-width: 1100px) {

  .banner_content_left,
  .banner_content_right {
    width: 100%;
  }

  .banner_content_inner {
    flex-direction: column;
    justify-content: flex-start;
    max-width: 640px;
    padding-right: 80px;
  }
}

@media screen and (max-width: 1000px) {

  .stack-on-1000,
  .block-stack-on-1000>.column {
    flex: none;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 900px) {

  .about_how2 .about_how2_process_block,
  .about_how2 .about_how2_screens_block {
    width: auto;
  }

  .stack-on-900,
  .block-stack-on-900>.column {
    flex: none;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

}

/* For screens smaller than md breakpoint */
@media (max-width: 767px) {
  .navbar-collapse {
    /* Center the collapsed navbar content */
    justify-content: center;
    text-align: center;
  }

  .navbar-nav {
    /* Stack the nav items vertically */
    flex-direction: column;
  }

  .nav-item {
    /* Add some spacing between nav items */
    margin: 0.5rem 0;
  }

  .profile-panel {
    /* Center the profile panel items */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
  }

  .profile-panel .nav-item {
    /* Remove spacing for profile panel nav items */
    margin: 0;
  }

  .profile-panel ul {
    /* Remove border for profile panel ul */
    border: none !important;
    padding: 0;
    /* Remove default padding */
    margin: 0;
  }

  .brew-roundup-row {
    width: 100%;
  }

  .roundup-row {
    margin-left: 1em;
    margin-right: 1em;
  }

}

@media screen and (max-width: 700px) {
  .banner_content_inner {
    padding-right: 10px;
  }

  h1 {
    margin-bottom: 10px !important;
  }

  .banner_content_title {
    font-size: calc(88px * 0.56);
  }
}

@media screen and (max-width: 600px) {

  .footer_subscribe input[type="email"],
  .footer_subscribe input[type="submit"] {
    width: 100%;
    text-align: center;
    border-radius: 8px;
  }

  .footer_subscribe form {
    display: block;
  }

  .custom_row {
    width: 100%;
    padding-left: 6vw;
    padding-right: 6vw;
    margin: 0;
    flex-flow: column;
    align-items: center;
  }
}


